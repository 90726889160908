// EnterVerificationCodeComponent.tsx
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

function EnterVerificationCodeComponent({ setStep, phoneNumber }: any) {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState<string | null>(null); // Add this state variable to hold an error message
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [isClicked, setIsClicked] = useState(false);
  const [isCodeRequested, setIsCodeRequested] = useState(false); // State to track if the code request has been made

  const apiUrl = process.env.REACT_APP_EXPRESS_API_URL || "";

  useEffect(() => {
    const handleKeyDown = (e: { key: string }) => {
      if (e.key === "Enter" && buttonRef.current) {
        // Check if the button is visible
        if (window.getComputedStyle(buttonRef.current).display !== "none") {
          buttonRef.current.click();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // if phone field is empty, send the verification code to the phone in the db
  // TODO: probably want some more checking here
  const requestVerificationCode = () => {
    if (isCodeRequested) return; // Early return if code has already been requested or retries exhausted
    console.log("Requesting verification code ...");
    setIsCodeRequested(true); // Set flag to true to avoid further requests
    if (!phoneNumber) {
      axios
        .post(
          `${apiUrl}/verify`,
          {},
          {
            headers: {
              authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          // Handle the response here, if necessary
          if (res.status === 401) {
            throw new Error("Token has expired. Please re-login.");
          } else if (res.status === 500) {
            throw new Error("An internal error has occurred.");
          }
        })
        .catch((error) => {
          const msg = error.response?.data?.status
            ? error.response?.data?.status
            : error.message;
          const new_msg = `Error requesting verification code: ${msg}. Try logging in again.`;
          setError(new_msg);
          setIsCodeRequested(true); // Allow further requests after an error
        });
    }
  };

  useEffect(() => {
    if (!phoneNumber && !isCodeRequested) {
      requestVerificationCode();
    }
  }); // Depend on phoneNumber and isCodeRequested to manage re-fetching logic

  const handleVerificationCodeSubmit = () => {
    if (isClicked) return; // Prevent multiple clicks. TODO: reset after X seconds
    setIsClicked(true); // disable the button

    let body = {};
    if (phoneNumber) {
      // brand new verification
      body = { code: verificationCode, phone: phoneNumber };
    } else {
      body = { code: verificationCode };
    }

    axios
      .post(`${apiUrl}/check`, body, {
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 401) {
          throw new Error(
            "Token has expired, or you have a previous code. Please re-login."
          );
        } else if (res.status === 500) {
          throw new Error("An internal error has occurred.");
        }

        // Now that 2fa has come back, we can fill in the right token to enable wasm
        // Modifying a form with a token is vulnerable to XSS (and was this way before I joined),
        // Ideally we'll fix the wasm to check cookies.
        // If account/site is 2fa enabled, skipping to this step should result in a token
        // that won't work
        var legacy_element = document.getElementById(
          "elementId"
        ) as HTMLInputElement;
        if (!legacy_element) {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = "token";
          input.id = "token";
          input.value = res.data.token;
          document.body.appendChild(input);
        } else {
          legacy_element.value = res.data.token;
        }
        setStep("verified");
      })
      .catch((error) => {
        let msg = error.response.data.status || error.message;
        setError(msg);
        setIsClicked(false);
      });
  };

  return (
    <div className="center">
      <img className="logo" src="images/logo_rgb.png" alt="PRENAV's Logo"></img>
      <form onSubmit={(e) => e.preventDefault()}>
        <table align="center" style={{ width: "80%" }}>
          <tbody>
            <tr>
              <td className="dlabel">SMS Verification Code: </td>
              <td>
                <input
                  id="verify"
                  type="text"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Verify"
                  className="input"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <br />
      <div
        className="dlabel"
        id="login_feedback"
        style={{ color: "#ff0000", display: "block" }}
      >
        &nbsp; {error} {/* Display the error message if there is one */}
      </div>
      <br />
      <button
        ref={buttonRef}
        onClick={handleVerificationCodeSubmit}
        className="login_button"
        value="Login"
        disabled={isClicked}
        style={{ backgroundColor: isClicked ? "gray" : "" }}
      >
        Verify Code
      </button>
      <div
        className="dlabel"
        style={{ margin: "0 auto", display: "block", paddingTop: "10%" }}
      >
        {" "}
        Powered by PRENAV Particle Engine
      </div>
      <div
        className="dlabel"
        style={{
          fontWeight: "normal",
          padding: "3%",
          paddingTop: "2%",
          margin: "0 auto",
          display: "block",
        }}
      >
        <a href="https://www.prenav.com" style={{ color: "white" }}>
          Prenav Home
        </a>{" "}
        |{" "}
        <a href="https://www.prenav.com/termsofuse" style={{ color: "white" }}>
          Terms of Use
        </a>{" "}
        |{" "}
        <a href="https://www.prenav.com/privacy" style={{ color: "white" }}>
          Privacy Policy
        </a>
      </div>
    </div>
  );
}

export default EnterVerificationCodeComponent;
