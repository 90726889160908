// StorePhoneComponent.tsx
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

function StorePhoneComponent({ setStep, setPhoneNumber }: any) {
  const phoneInputRef = useRef(null);
  const [error, setError] = useState(null); // Add this state variable to hold an error message
  const intlTelInputRef = useRef<any>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isClicked, setIsClicked] = useState(false);

  const apiUrl = process.env.REACT_APP_EXPRESS_API_URL || "";

  useEffect(() => {
    const handleKeyDown = (e: { key: string }) => {
      if (e.key === "Enter" && buttonRef.current) {
        // Check if the button is visible
        if (window.getComputedStyle(buttonRef.current).display !== "none") {
          buttonRef.current.click();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const requestVerificationCode = () => {
    if (isClicked) return; // Prevent multiple clicks. TODO: reset after X seconds
    setIsClicked(true); // Disable the button

    let body = {};
    if (intlTelInputRef.current) {
      const fullPhoneNumber = intlTelInputRef.current.getNumber();
      setPhoneNumber(fullPhoneNumber); // This number will include the country code
      body = { phone: fullPhoneNumber };
    }
    axios
      .post(`${apiUrl}/verify`, body, {
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 401) {
          throw new Error("Token has expired. Please re-login.");
        } else if (res.status === 500) {
          throw new Error("An internal error has occurred.");
        }
        setStep("enter-verification-code");
      })
      .catch((error) => {
        let msg = error.response.data.status || error.message;
        setError(msg);
        setIsClicked(false);
      });
  };

  useEffect(() => {
    if (window.intlTelInput && phoneInputRef.current) {
      intlTelInputRef.current = window.intlTelInput(phoneInputRef.current, {
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        onlyCountries: ["us", "ca", "mx", "jp"],
        nationalMode: false,
      });
    }
  }, []);

  return (
    <div className="center">
      <img className="logo" src="images/logo_rgb.png" alt="PRENAV's Logo"></img>

      <form onSubmit={(e) => e.preventDefault()}>
        <table align="center" style={{ width: "80%" }}>
          <tbody>
            <tr>
              <td className="dlabel">Phone Number: </td>
              <td>
                <input
                  id="phone"
                  ref={phoneInputRef}
                  type="tel"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Add Phone Number"
                  className="input"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <br />
      <div
        className="dlabel"
        id="login_feedback"
        style={{ color: "#ff0000", display: "block" }}
      >
        &nbsp; {error} {/* Display the error message if there is one */}
      </div>
      <br />
      <button
        ref={buttonRef}
        onClick={requestVerificationCode}
        className="login_button"
        value="Login"
        disabled={isClicked}
        style={{ backgroundColor: isClicked ? "gray" : "" }}
      >
        Set Phone Number
      </button>
      <div
        className="dlabel"
        style={{ margin: "0 auto", display: "block", paddingTop: "10%" }}
      >
        {" "}
        Powered by PRENAV Particle Engine
      </div>
      <div
        className="dlabel"
        style={{
          fontWeight: "normal",
          padding: "3%",
          paddingTop: "2%",
          margin: "0 auto",
          display: "block",
        }}
      >
        <a href="https://www.prenav.com" style={{ color: "white" }}>
          Prenav Home
        </a>{" "}
        |{" "}
        <a href="https://www.prenav.com/termsofuse" style={{ color: "white" }}>
          Terms of Use
        </a>{" "}
        |{" "}
        <a href="https://www.prenav.com/privacy" style={{ color: "white" }}>
          Privacy Policy
        </a>
      </div>
    </div>
  );
}

export default StorePhoneComponent;
