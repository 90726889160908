import React, { Dispatch, SetStateAction, useEffect } from 'react';

type WASMComponentProps = {
    setStep: Dispatch<SetStateAction<string>>;
    step: string;
};

function WASMComponent({ setStep, step  }: WASMComponentProps) {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "webapps.js?" + Math.random().toString().substr(2, 8);
        script.async = true;
        script.onload = () => {
            console.log('Script loaded');
        };
        document.head.appendChild(script);

        const parentElement = document.getElementById("parent");
        const dummyElement = document.getElementById("dummy");
        const minimalElement = document.getElementById("minimal");

        if (!parentElement || !dummyElement || !minimalElement) {
            console.error("Missing parent, dummy or minimal element. Unable to set style");
            return;
        }
        parentElement.style.display = "none";
        dummyElement.style.display = "none";
        minimalElement.style.display = "block";

        return () => {
            // Optionally, clean up the script from the head
            document.head.removeChild(script);

            minimalElement.style.display = "none";
            parentElement.style.display = "block";
            dummyElement.style.display = "block";
        };

    }, []);
    return (
        <div>
            {}

        </div>
    );

}

export default WASMComponent;