import './App.css';
import React, { useState } from 'react';
import WASMComponent from './Components/WASMComponent';
import UsernamePasswordFormComponent from './Components/UsernamePasswordFormComponent';
import StorePhoneComponent from './Components/StorePhoneComponent';
import EnterVerificationCodeComponent from './Components/EnterVerificationCodeComponent';

function App() {
    const [step, setStep] = useState('login');
    const [phoneNumber, setPhoneNumber] = useState(''); // Here

    return (
        <div>
            {step === 'login' && <UsernamePasswordFormComponent setStep={setStep} />}
            {step === 'store-phone' && <StorePhoneComponent setStep={setStep} step={step} setPhoneNumber={setPhoneNumber} />}
            {step === 'enter-verification-code' && <EnterVerificationCodeComponent setStep={setStep} phoneNumber={phoneNumber} />}
            {step === 'verified' && <WASMComponent setStep={setStep} step={step}/>}
            {/* Other components based on status */}
        </div>
    );
}

export default App;
