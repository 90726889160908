import '../App.css';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

type UsernamePasswordFormComponentProps = {
    setStep: (status: string) => void;
};

function UsernamePasswordFormComponent({ setStep }: UsernamePasswordFormComponentProps) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null); // Add this state variable to hold an error message
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [isClicked, setIsClicked] = useState(false);

    const apiUrl = process.env.REACT_APP_EXPRESS_API_URL ? process.env.REACT_APP_EXPRESS_API_URL : "";

    useEffect(() => {
        const handleKeyDown = (e: { key: string; }) => {
          if (e.key === 'Enter' && buttonRef.current) {
            // Check if the button is visible
            if (window.getComputedStyle(buttonRef.current).display !== 'none') {
              buttonRef.current.click();
            }
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup listener when component unmounts
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      });

    const handleSubmit = () => {
        if (isClicked) return; // Prevent multiple clicks. TODO: reset after X seconds
        var body = { username: username, password: password};

        setIsClicked(true);  // Disable the button

        // hit server to get token
        axios.post(`${apiUrl}/login`, body)
        .then(res => {
            // Ensure the response is valid before continuing
            if (res.status !== 200) {
                if (res.status === 401) {
                    throw new Error("Incorrect username or password.");
                }
                else if (res.status === 500) {
                    throw new Error("An internal error has occurred.");
                }
                throw new Error('Failed to login.');
            }

            // Define body for the second request and continue the chain
            return res.data;
        })
        .then((data) => {
            setError(null);

            sessionStorage.setItem('token', data.token);
            
            if (data.status === 'send-code') {
                setStep('enter-verification-code');
            } else if (data.status === 'need-to-persist-phone') {
                setStep('store-phone');
            } else if (data.status === 'verified') {
                // This I believe is vulnerable to XSS (and was this way before I joined)
                // If account/site is 2fa enabled, skipping to this step should result in a token
                // that won't work
                var legacy_element =  document.getElementById('elementId') as HTMLInputElement
                if (!legacy_element) {
                    const input = document.createElement('input');
                    input.type = "hidden";
                    input.name = "token";
                    input.id = "token";
                    input.value = data.token;
                    document.body.appendChild(input);       
                } else {
                    legacy_element.value = data.token;
                }
                setStep('verified');
            } else {
                console.log('how did this happen?');
                var msg = 'Invalid status code.';
                setError(msg);
                setIsClicked(false);
            }
        })
        .catch(error => {
            // Handle any errors that occurred in the above chain
            var msg = error.response.data.status ? error.response.data.status : error.message;
            setError(msg);
            setIsClicked(false);
        });
    };
    return (
        <div className="center">
            <img className="logo" src="images/logo_rgb.png" alt="PRENAV's logo"></img>
            <form>

                <table align="center" style={{ width: '80%' }}>
                    <tbody>
                        <tr><td className="dlabel">Username: </td>
                            <td>
                                <input type="text" onChange={(e) => setUsername(e.target.value)} placeholder="Username" className="input" autoComplete="username" />
                            </td>
                        </tr>
                        <tr>
                            <td className="dlabel">Password: </td>
                            <td>
                                <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="input" autoComplete="current-password" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form><br />
            <div className="dlabel" id="login_feedback" style={{ color: '#ff0000', display: 'block' }}>
                &nbsp;        {error} {/* Display the error message if there is one */}
            </div>
            <br />
            <button ref={buttonRef} onClick={handleSubmit} className='login_button' value="Login" disabled={isClicked} style={{ backgroundColor: isClicked ? 'gray' : '' }}  // Optional: change color to gray when clicked
>Login</button>
            <div className="dlabel" style={{ margin: '0 auto', display: 'block', paddingTop: '10%' }}> Powered by PRENAV Particle Engine</div>
            <div className="dlabel" style={{ fontWeight: 'normal', padding: '3%', paddingTop: '2%', margin: '0 auto', display: 'block' }}>
                <a href="https://www.prenav.com" style={{ color: 'white' }}>Prenav Home</a> | 
                <a href="https://www.prenav.com/termsofuse" style={{ color: 'white' }}>Terms of Use</a> | 
                <a href="https://www.prenav.com/privacy" style={{ color: 'white' }}>Privacy Policy</a>
                </div>
        </div>
    );
}

export default UsernamePasswordFormComponent;